<template>
  <div class="user-action">
    <!-- 导航提示标题 -->
    <ml-tip :showHeader="false" :content="content" />
    <ml-tip class="scroll-box" :showHeader="false" style="border: 0">
      <el-scrollbar :height="tabberHeight(185)">
        <!-- 账户表单 -->
        <ml-form
          ref="userRef"
          labelWidth="200px"
          style="width: 1200px; margin: 40px auto"
          :model="userForm"
          :rules="userFormRules"
        >
          <!-- 提示标题 -->
          <el-divider content-position="left" style="font-weight: bold">账号信息</el-divider>
          <el-row>
            <el-col :span="12">
              <!-- 输入框-账号 -->
              <ml-input
                prop="userCode"
                label="账号:"
                placeholder="请输入账号"
                v-model.trim="userForm.userCode"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-密码 -->
              <ml-input
                prop="userPass"
                label="账号密码:"
                placeholder="请输入密码"
                type="password"
                v-model.trim="userForm.userPass"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 阿米巴 -->
              <ml-select
                prop="companyId"
                placeholder="请选择阿米巴"
                :options="unitOptions"
                label="阿米巴:"
                keyName="companyId"
                labelName="companyName"
                valueName="companyId"
                v-model="userForm.companyId"
                @change="selectUnit"
              />
            </el-col>
            <el-col :span="12">
              <!-- 工作单位 -->
              <ml-input
                prop="workUnit"
                label="工作单位:"
                placeholder="请输入工作单位"
                v-model.trim="userForm.workUnit"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-用户名 -->
              <ml-input
                prop="userName"
                label="用户名:"
                placeholder="请输入用户名"
                v-model.trim="userForm.userName"
              />
            </el-col>
            <el-col :span="12">
              <!-- 输入框-职位 -->
              <ml-input
                prop="job"
                label="职位:"
                placeholder="请输入职位"
                v-model.trim="userForm.job"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 角色 -->
              <ml-select
                prop="roleIdList"
                placeholder="请选择角色"
                multiple
                :options="roleData"
                label="角色:"
                keyName="roleId"
                labelName="roleName"
                valueName="roleId"
                v-model="userForm.roleIdList"
              />
            </el-col>
            <el-col :span="12">
              <!-- 角色 -->
              <ml-select
                prop="userType"
                placeholder="请选择角色类型"
                :options="nRoleData"
                label="角色类型:"
                keyName="key"
                labelName="value"
                valueName="key"
                v-model="userForm.userType"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 输入框-联系电话 -->
              <ml-input
                prop="telephone"
                label="联系电话:"
                placeholder="请输入联系电话"
                v-model.trim="userForm.telephone"
              />
            </el-col>
            <el-col :span="12">
              <!-- 所属项目 -->
              <ml-cascader
                v-if="['proprietor'].includes(userForm.userType)"
                :prop="['proprietor'].includes(userForm.userType) ? 'projectVos' : 'null'"
                :props="{ multiple: true, label: 'projectName', value: 'projectId' }"
                label="所属项目:"
                :options="projectData"
                placeholder="请选择所属项目"
                v-model="userForm.projectVos"
                style="width: 100%"
              />
            </el-col>
          </el-row>

          <el-row>
            <el-col :span="12">
              <!-- 树 -管理区域 -->
              <ml-tree
                ref="regionRef"
                prop="regionArray"
                label="管理区域:"
                :data="areaData"
                placeholder="请选择管理区域"
                v-model="userForm.regionArray"
                v-model:selectData="userForm.regions"
                treeClass="ml-tree"
                height="200px"
                node-key="code"
                :props="{ label: 'name' }"
              />
            </el-col>
            <el-col :span="12"> </el-col>
          </el-row>

          <!-- 提交按钮 -->
          <ml-button
            submitText="提交"
            submitIcon="el-icon-upload2"
            cancelIcon="el-icon-circle-close"
            cancelText="返回上一页"
            @click-submit="clickSubmitUser"
            @click-cancel="clickCancelUser"
          />
        </ml-form>
      </el-scrollbar>
    </ml-tip>
  </div>
</template>

<script>
import { computed, onMounted, reactive, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'
import {
  getTabberData,
  mobilePhoneRegExp,
  searchParams,
  getProjectsObj,
  tabberHeight
} from '@/utils'
export default {
  name: 'UserAction',
  setup() {
    const { commit, getters, dispatch } = useStore()
    const route = useRoute()
    const router = useRouter()
    const isBusinessDepartment = computed(() => getters.isBusinessDepartment)
    // 获取路径参数
    const { userId } = route.query
    // 设置导航标题文本
    const content = ref(userId ? '修改账户' : '添加账户')
    /* 开始 用户表单 **********************************/
    // 账户表单配置
    const userRef = ref()
    const regionRef = ref()
    const oldPwd = ref('')
    const userForm = reactive({
      userCode: '',
      userPass: '',
      companyId: '',
      workUnit: '',
      userName: '',
      telephone: '',
      job: '',
      projectVos: [],
      regionArray: [],
      regions: [],
      roleId: '',
      userType: '',
      roleIdList: []
    })
    // 单位名下拉列表
    const unitOptions = ref([])
    // 管理区域配置
    const areaData = ref([])
    // 角色配置
    const roleData = ref([])
    const roleDataApp = ref([])
    const nRoleData = ref([])
    // 所属项目配置
    const projectData = ref([])
    // 处理管辖区域
    const getAreaData = companyId => {
      return (
        unitOptions.value
          .filter(item => companyId === item.companyId)
          .map(item => item.regions)[0] || []
      )
    }
    // 处理角色
    const getRoleData = async () => {
      // const roleParams = { parent: 0 }
      // if (isBusinessDepartment.value) {
      //   roleParams.companyId = companyId
      // }
      const { data } = await getTabberData(dispatch, 'fetchGetSAysRoles', {})
      roleData.value = data || []
    }
    // 处理所属项目
    const getProjectData = async companyId => {
      const projectParams = { parent: 0 }
      if (isBusinessDepartment.value) {
        projectParams.companyId = companyId
      }
      const { data } = await getTabberData(dispatch, 'fetchGetProjectsByCompany', projectParams)
      projectData.value = data || []
    }
    // 处理角色
    const getRouleData = async companyId => {
      const companyNode = unitOptions.value.filter(item => item.companyId === companyId)
      if (companyNode[0].companyCode === 'business_department') {
        nRoleData.value = roleDataApp.value.filter(item => item.key === 'division_manager')
      } else {
        nRoleData.value = roleDataApp.value.filter(item => item.key != 'division_manager')
      }
    }
    const selectUnit = companyId => {
      regionRef.value.customTreeRef.setCheckedKeys([])
      userForm.regionArray = []
      userForm.regions = []
      userForm.projectVos = []
      // userForm.roleId = ''
      userForm.userType = ''
      areaData.value = getAreaData(companyId)

      getRouleData(companyId)
    }
    watch(
      () => userForm.companyId,
      newvalue => {
        areaData.value = getAreaData(newvalue)
        getProjectData(newvalue)
        getRouleData(newvalue)
      }
    )
    // 账户新增修改校验
    const userFormRules = {
      userCode: [{ required: true, message: '请输入账号', trigger: 'blur' }],
      userPass: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      companyId: [{ required: true, message: '请选择阿米巴', trigger: 'blur' }],
      workUnit: [{ required: true, message: '请输入工作单位', trigger: 'blur' }],
      userName: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
      projectVos: [{ required: true, message: '至少选择一个所属项目', trigger: 'blur' }],
      telephone: [
        { required: true, message: '请输入联系电话', trigger: 'blur' },
        {
          validator: (rule, val) => mobilePhoneRegExp.test(val),
          message: '请输入正确联系电话',
          trigger: 'blur'
        }
      ],
      //regionArray: [{ required: true, message: '至少选择一个管理区域', trigger: 'blur' }],
      roleIdList: [{ required: true, message: '请选择角色', trigger: 'blur' }],
      userType: [{ required: true, message: '请选择角色类型', trigger: 'blur' }]
    }
    // 提交表单
    const clickSubmitUser = () => {
      userRef.value.CustomFormRef.validate()
        .then(() => {
          // 处理参数
          const userFormParams = searchParams(userForm, ['projectVos'])
          // 处理所属项目
          if (userForm.projectVos.length > 0) {
            const projectKeyObjArr = getProjectsObj(userForm.projectVos, projectData.value)
            if (projectKeyObjArr.length > 0) {
              userFormParams.projectVos = projectKeyObjArr
            }
          }
          // 处理密码是否改变
          if (oldPwd.value === userFormParams.userPass) {
            delete userFormParams.userPass
          }

          if (content.value === '添加账户') {
            dispatch('fetchAddSysUser', userFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/accountList' })
              }
            })
          } else {
            userFormParams.userId = userId
            dispatch('fetchUpdateSysUser', userFormParams).then(data => {
              if (data && data.code === 200) {
                commit('setError', {
                  status: true,
                  title: data.message,
                  message: '正在获取新的数据...',
                  type: 'success'
                })
                router.push({ path: '/accountList' })
              }
            })
          }
        })
        .catch(() => {})
    }
    // 取消表单
    const clickCancelUser = () => {
      router.push({ path: '/accountList' })
    }
    /* 结束 用户表单 **********************************/
    onMounted(async () => {
      const appRole = await getTabberData(dispatch, 'fetchGetDictChildren', {
        dictType: 'app_user_type',
        dictValue: 'app_user_type'
      })
      roleDataApp.value = appRole.data || []
      getRoleData()
      // 获取单位名
      const { data } = await getTabberData(dispatch, 'fetchGetCompanyInfos', {})
      unitOptions.value = data || []
      if (data.length > 0) {
        userForm.companyId = data[0].companyId
      }

      if (content.value === '修改账户') {
        await dispatch('fetchGetSysUser', userId).then(data => {
          if (data && data.code === 200) {
            Object.keys(userForm).map(item => {
              if (['projectVos'].includes(item)) {
                if (data.data[item]) {
                  userForm[item] = data.data[item].map(result => [result.projectId])
                }
              } else if (['roleIdList'].includes(item)) {
                userForm[item] = data.data.sysRoleVo.map(result => result.roleId)
              } else {
                userForm[item] = data.data[item]
              }
            })
            console.log('userForm', userForm)
            oldPwd.value = data.data.userPass
          }
        })
      }
    })
    return {
      userRef,
      regionRef,
      content,
      userForm,
      unitOptions,
      areaData,
      roleData,
      projectData,
      userFormRules,
      clickSubmitUser,
      clickCancelUser,
      selectUnit,
      tabberHeight,
      nRoleData
    }
  }
}
</script>

<style lang="scss">
.user-action {
  position: relative;
  .ml-tree {
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    padding: 10px 15px;
    .el-tree {
      > .el-tree-node {
        > .el-tree-node__children {
          > .el-tree-node {
            > .el-tree-node__children {
              white-space: break-spaces;
              > .el-tree-node {
                display: inline-block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
